import * as Airtable from 'airtable';

const base = new Airtable({
  apiKey: 'pattCKvlVPibAMZw1.6eb525b7643d3b8c59cfed53e7dd58128f181dca3f4a155ce614303fa1a29329'
}).base('appo8g9TjuRyPjwA9');

export const employersTable = base('Companies');

export interface Employer {
  id: string;
  name: string;
  industry: string;
  website: string;
  shortDescription: string;
  employeeCount: string;
  schoolDistrict: string;
  pathways: string[];
  moreNotes: string;
  rank: number;
  jurisdiction: string;
}

export async function fetchEmployers(options = {}): Promise<Employer[]> {
  const { searchQuery = '', pageSize = 100, offset = null } = options;

  let filterFormula = '';
  if (searchQuery) {
    const searchTerms = searchQuery
      .toLowerCase()
      .split(' ')
      .filter(term => term.length > 0);

    // Create the main filter that requires at least one term to match
    const mainFilter = searchTerms.map(
      term => `OR(
        SEARCH('${term}', LOWER({Company Name})),
        SEARCH('${term}', LOWER({Industry})),
        SEARCH('${term}', LOWER({Website})),
        SEARCH('${term}', LOWER({Short Description})),
        SEARCH('${term}', LOWER({School District})),
        SEARCH('${term}', LOWER({Pathways})),
        SEARCH('${term}', LOWER({More Notes})),
        SEARCH('${term}', LOWER({Jurisdiction}))
      )`
    );

    filterFormula = mainFilter.length > 1 ? `OR(${mainFilter.join(', ')})` : mainFilter[0];
  }

  try {
    const selectOptions: any = {
      view: 'Official',
      maxRecords: 1000,
      filterByFormula: filterFormula
    };

    if (typeof offset === 'number') {
      selectOptions.offset = offset;
    }

    const records = await employersTable.select(selectOptions).all();

    if (searchQuery) {
      const searchTerms = searchQuery
        .toLowerCase()
        .split(' ')
        .filter(term => term.length > 0);

      // Map records and calculate match count
      const recordsWithRank = records.map(record => {
        const allFields = [
          record.get('Company Name') as string,
          record.get('Industry') as string,
          record.get('Website') as string,
          record.get('Short Description') as string,
          record.get('School District') as string,
          (record.get('Pathways') as string[])?.join(' ') || '',
          record.get('More Notes') as string,
          record.get('Jurisdiction') as string
        ]
          .join(' ')
          .toLowerCase();

        // Count how many search terms match
        const matchCount = searchTerms.filter(term => allFields.includes(term)).length;

        return {
          id: record.id,
          name: record.get('Company Name') as string,
          industry: record.get('Industry') as string,
          website: record.get('Website') as string,
          matchCount
        };
      });

      // Sort by match count (descending) and then by name
      return recordsWithRank.sort((a, b) => {
        if (a.matchCount !== b.matchCount) {
          return b.matchCount - a.matchCount;
        }
        return a.name.localeCompare(b.name);
      });
    }

    return records.map(record => ({
      id: record.id,
      name: record.get('Company Name') as string,
      industry: record.get('Industry') as string,
      website: record.get('Website') as string
      //   shortDescription: (record.get('Short Description') as string) || '',
      //   employeeCount: (record.get('# Employees') as number) || 0,
      //   schoolDistrict: (record.get('School District') as string) || '',
      //   pathways: (record.get('Pathways') as string[]) || [],
      //   moreNotes: (record.get('More Notes') as string) || '',
      //   rank: (record.get('Rank') as number) || 0,
      //   jurisdiction: (record.get('Jurisdiction') as string) || ''
    }));
  } catch (error) {
    console.error('Error fetching employers:', error);
    throw error;
  }
}
