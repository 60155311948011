<template>
  <v-card tile dark class="white--text program-setup-wizard__dialog program-setup-wizard__site">
    <v-stepper v-model="currentStep" class="transparent" depressed tile>
      <v-stepper-header hidden class="transparent">
        <v-stepper-step
          :complete="currentStep > 1"
          step="1"
          color="white"
          editable
          @click="currentStep = 1"
        >
          Browse Employers
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="currentStep > 2"
          step="2"
          color="white"
          editable
          @click="currentStep = 2"
        >
          Enter Website Directly
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Step 1: Browse Employers -->
        <v-stepper-content step="1">
          <div class="d-flex justify-center pt-9">
            <v-icon color="yellow" x-large>mdi-magnify</v-icon>
          </div>
          <v-card-title class="font-weight-bold text-h4 justify-center pt-4 pb-2">
            <h4>Browse employers</h4>
          </v-card-title>
          <div class="d-flex justify-center mb-6 mt-3">
            <v-btn dark small depressed rounded @click="goToWebsiteStep">
              Enter Website Directly
            </v-btn>
          </div>

          <v-container class="pb-12">
            <v-row justify="center">
              <v-col cols="12" sm="10" md="8">
                <v-text-field
                  v-model="searchQuery"
                  :loading="searchLoading"
                  label="Search employers"
                  placeholder="Search by name, industry, location, description, etc."
                  prepend-inner-icon="mdi-magnify"
                  :clearable="true"
                  outlined
                  rounded
                  hide-details
                  class="search-field"
                  color="white"
                  @keyup.enter="searchEmployers"
                >
                  <template #append-outer>
                    <v-fade-transition leave-absolute>
                      <v-btn
                        icon
                        color="white"
                        :loading="searchLoading"
                        class="search-button"
                        @click="searchEmployers"
                      >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-fade-transition>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="searchResults.length" justify="center" class="mt-6">
              <v-col cols="12">
                <div class="d-flex mb-4">
                  <v-btn text :color="showingSaved ? 'yellow' : 'white'" @click="toggleSavedFilter">
                    <v-icon left>{{
                      showingSaved ? 'mdi-bookmark' : 'mdi-bookmark-outline'
                    }}</v-icon>
                    {{ showingSaved ? 'Showing Saved' : 'Show Saved' }}
                  </v-btn>
                </div>

                <v-data-table
                  v-model="selectedEmployers"
                  :headers="headers"
                  :items="filteredResults"
                  :loading="searchLoading"
                  show-select
                  item-key="id"
                  dark
                  class="employer-results"
                  :footer-props="{
                    'items-per-page-options': [10, 25, 50, 100, 250, 500, 1000]
                  }"
                  :show-select-header="false"
                  height="400px"
                  fixed-header
                  @scroll="handleScroll"
                >
                  <template #[`item.name`]="{ item }">
                    <span class="text-h6 font-weight-bold">{{ item.name }}</span>
                  </template>
                  <template #[`item.createProgram`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="createProgram(item)">
                          <v-icon color="yellow" large>mdi-lightning-bolt</v-icon>
                        </v-btn>
                      </template>
                      <span>Create Program Now</span>
                    </v-tooltip>
                  </template>
                  <template #[`item.saveForLater`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" @click="saveForLater(item)">
                          <v-icon :color="savedEmployers.has(item.id) ? 'yellow' : ''">
                            {{
                              savedEmployers.has(item.id) ? 'mdi-bookmark' : 'mdi-bookmark-outline'
                            }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        savedEmployers.has(item.id) ? 'Remove from Saved' : 'Save for Later'
                      }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <div class="d-flex justify-end align-center mt-4">
                  <!-- <v-btn
                    color="primary"
                    :disabled="!selectedEmployers.length"
                    @click="createMultiplePrograms"
                  >
                    Create Programs ({{ selectedEmployers.length }})
                  </v-btn> -->
                  <v-btn text :disabled="!selectedEmployers.length" @click="saveMultipleForLater">
                    Save Selected for Later
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>

        <!-- Step 2: Enter Website -->
        <v-stepper-content step="2">
          <div class="d-flex justify-center pt-9">
            <v-icon color="yellow" x-large>mdi-lightning-bolt</v-icon>
          </div>
          <v-card-title class="font-weight-bold text-h4 justify-center pt-4 pb-2">
            <h4>Enter an employer's website</h4>
          </v-card-title>
          <div class="d-flex justify-center mt-3">
            <v-btn small dark depressed rounded @click="goToResearchStep"> Browse Employers </v-btn>
          </div>
          <v-container>
            <v-row justify="center" class="">
              <v-col class="mt-auto mb-auto" cols="12" sm="10" md="8">
                <v-text-field
                  v-model="inputWebsite"
                  label="Enter employer website"
                  placeholder="https://www.employer.com"
                  :clearable="!loading"
                  outlined
                  x-large
                  rounded
                  class="mt-5"
                  autofocus
                  hide-details
                  density="comfortable"
                  variant="filled"
                  color="white"
                  :disabled="loading"
                  de
                  @keyup.enter="submitSite"
                >
                  <template #append-outer>
                    <v-fade-transition leave-absolute class="ml-2">
                      <v-icon :disabled="loading" color="white" @click="submitSite"
                        >mdi-send</v-icon
                      >
                    </v-fade-transition>
                  </template>
                  <!-- TODO: Loading Icon v-progress-circular -->
                  <!-- TODO: Success details  -->
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-8 program-setup-wizard__site-callout" no-gutters>
              <v-col
                class="program-setup-wizard__dialog--tip-description text-center"
                cols="12"
                sm="10"
                md="8"
              >
                <p v-if="!loading || errors" class="pt-3">
                  Make, setup and publish programs to offer on PilotCity
                </p>
                <v-progress-linear
                  v-if="loading && !errors"
                  v-model="loadingDataValue.value"
                  :indeterminate="loadingDataValue.value < 1"
                  color="white"
                />
                <p v-if="loading && !errors" class="pt-3">
                  {{ loadingDataValue.message }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import {
  defineComponent,
  reactive,
  nextTick,
  ref,
  computed,
  onMounted
} from '@vue/composition-api';
import { fetchEmployers } from '@/services/airtable';

export default defineComponent({
  name: 'SetupSiteWizard',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const currentStep = ref(2); // Start at step 2 instead of step 1
    const inputWebsite = ref('');
    const loadingDataValue = reactive({
      value: 0,
      message: 'Thanks!\nSuggesting possible program descriptions'
    });
    const searchQuery = ref('');
    const searchResults = ref([]);
    const selectedEmployers = ref([]);
    const searchLoading = ref(false);
    const savedEmployers = ref(new Set()); // Track saved employers by ID
    const showingSaved = ref(JSON.parse(localStorage.getItem('showingSavedFilter')) || false);
    const currentPage = ref(1);
    const pageSize = 50;
    const hasMoreResults = ref(true);
    const offset = ref(null);

    const headers = [
      {
        text: 'Save',
        value: 'saveForLater',
        sortable: false,
        align: 'center',
        width: '5%'
      },
      {
        text: 'Create',
        value: 'createProgram',
        sortable: false,
        align: 'center',
        width: '5%'
      },
      {
        text: 'Employer',
        value: 'name',
        align: 'start',
        width: '30%'
      },
      {
        text: 'Industry',
        value: 'industry',
        width: '15%'
      },
      { text: 'Website', value: 'website', width: '25%' },

      //   text: 'Description',
      //   value: 'shortDescription',
      //   width: '20%'
      // },
      // {
      //   text: 'Employees',
      //   value: 'employeeCount',
      //   width: '10%'
      // },
      // {
      //   text: 'Jurisdiction',
      //   value: 'jurisdiction',
      //   width: '10%'
      // },

      {
        text: 'Select',
        value: 'data-table-select',
        sortable: false,
        align: 'center',
        width: '5%'
      }
    ];

    // Add computed property for filtered results
    const filteredResults = computed(() => {
      if (!showingSaved.value) return searchResults.value;
      return searchResults.value.filter(employer => savedEmployers.value.has(employer.id));
    });

    async function submitSite() {
      emit('submitSite', this.inputWebsite);
      await nextTick();
      await this.loadingData();
    }
    async function loadingData(duration = 30, parts = 5) {
      const messages = [
        'Submitting URL',
        'Retrieving Data',
        'Analyzing keywords',
        `Analyzing ${inputWebsite.value} description`,
        'Generating Scopes'
      ];
      const step = duration / parts;
      for (let n = 0, i = 0; n < duration; n += step, i++) {
        if (!props.loading) break;
        // eslint-disable-next-line no-await-in-loop
        await new Promise(_res => {
          setTimeout(() => {
            this.loadingDataValue.value = (n / 30) * 100;
            this.loadingDataValue.message = messages[i];
            _res();
          }, step * 1000);
        });
      }
      this.loadingDataValue.value = 95;
      this.loadingDataValue.message = 'Finishing Up..';
    }
    async function loadEmployers(isLoadingMore = false) {
      if (!isLoadingMore) {
        searchResults.value = [];
        currentPage.value = 1;
        offset.value = null;
      }

      searchLoading.value = true;
      try {
        const results = await fetchEmployers({
          searchQuery: searchQuery.value,
          pageSize,
          offset: offset.value
        });

        if (results.length < pageSize) {
          hasMoreResults.value = false;
        }

        if (isLoadingMore) {
          searchResults.value = [...searchResults.value, ...results];
        } else {
          searchResults.value = results;
        }

        // Update offset for next page
        if (results.length === pageSize) {
          offset.value = results[results.length - 1].id;
        }
      } catch (error) {
        console.error('Failed to load employers:', error);
      } finally {
        searchLoading.value = false;
      }
    }
    async function searchEmployers() {
      await loadEmployers();
      emit('searchEmployers', searchQuery.value);
    }
    function createProgram(employer) {
      // Set the website in the input field
      inputWebsite.value = employer.website;

      // Navigate to step 2
      currentStep.value = 2;

      // Emit the original event
      emit('createProgram', employer);
    }
    function persistSavedEmployers() {
      localStorage.setItem('savedEmployers', JSON.stringify([...savedEmployers.value]));
    }
    function saveForLater(employer) {
      if (savedEmployers.value.has(employer.id)) {
        savedEmployers.value.delete(employer.id);
      } else {
        savedEmployers.value.add(employer.id);
      }
      persistSavedEmployers();
      emit('saveForLater', employer);
    }
    function createMultiplePrograms() {
      emit('createMultiplePrograms', selectedEmployers.value);
    }
    function saveMultipleForLater() {
      // Add all selected employers to the savedEmployers Set
      selectedEmployers.value.forEach(employer => {
        savedEmployers.value.add(employer.id);
      });

      persistSavedEmployers();

      // Emit the original event
      emit('saveMultipleForLater', selectedEmployers.value);

      // Clear the selection after saving
      selectedEmployers.value = [];
    }

    // Fix navigation functions
    function goToWebsiteStep() {
      currentStep.value = 2;
    }

    function goToResearchStep() {
      currentStep.value = 1;
    }

    // Add initial data loading
    onMounted(async () => {
      searchLoading.value = true;
      try {
        const results = await fetchEmployers();
        searchResults.value = results;
      } catch (error) {
        console.error('Failed to load initial employers:', error);
      } finally {
        searchLoading.value = false;
      }
    });

    // Add these functions at the setup level
    function loadSavedEmployers() {
      const saved = localStorage.getItem('savedEmployers');
      if (saved) {
        savedEmployers.value = new Set(JSON.parse(saved));
      }
    }

    // Load saved employers when component is created
    loadSavedEmployers();

    // Add new function to persist filter state
    function persistFilterState() {
      localStorage.setItem('showingSavedFilter', JSON.stringify(showingSaved.value));
    }

    // Update the button click handler to persist the state
    function toggleSavedFilter() {
      showingSaved.value = !showingSaved.value;
      persistFilterState();
    }

    // Add infinite scroll handler
    async function handleScroll({ target }) {
      const table = target;
      if (
        !searchLoading.value &&
        hasMoreResults.value &&
        table.scrollTop + table.clientHeight >= table.scrollHeight - 200
      ) {
        await loadEmployers(true);
      }
    }

    return {
      currentStep,
      inputWebsite,
      loadingDataValue,
      loadingData,
      submitSite,
      searchQuery,
      searchResults,
      selectedEmployers,
      searchLoading,
      headers,
      searchEmployers,
      createProgram,
      saveForLater,
      createMultiplePrograms,
      saveMultipleForLater,
      goToWebsiteStep,
      goToResearchStep,
      savedEmployers,
      showingSaved,
      filteredResults,
      toggleSavedFilter,
      handleScroll,
      hasMoreResults
    };
  }
});
</script>

<style scoped>
/* Add these styles to make the stepper transparent and white text */
:deep(.v-stepper) {
  background: transparent !important;
}

:deep(.v-stepper__header) {
  box-shadow: none;
}

:deep(.v-stepper__step__step) {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

:deep(.v-stepper__step--active .v-stepper__step__step) {
  background-color: white !important;
}

:deep(.v-stepper__step__step.primary) {
  background-color: white !important;
}

:deep(.v-stepper__label) {
  color: white !important;
}

:deep(.employer-results) {
  background: rgba(255, 255, 255, 0.05) !important;
  border-radius: 8px;
  width: 100% !important;
  max-width: none !important;

  .v-data-table {
    width: 100% !important;
  }

  .v-data-table__wrapper {
    width: 100% !important;
    overflow-x: auto !important;
  }

  table {
    width: 100% !important;
    min-width: 800px !important; /* Ensures readability on very small screens */
  }

  .v-data-table-header {
    background: rgba(255, 255, 255, 0.1) !important;
  }

  .v-data-footer {
    background: transparent !important;
  }

  tbody tr:hover {
    background: rgba(255, 255, 255, 0.1) !important;
  }

  .v-icon {
    color: rgba(255, 255, 255, 0.7);
  }

  .v-data-table__wrapper {
    td {
      padding: 8px 16px !important; /* Adjust padding to accommodate larger text */
    }

    td:nth-last-child(-n + 3) {
      /* Last 3 columns (actions) */
      padding: 0 4px !important;
      width: 48px !important;
    }
  }

  .v-btn {
    margin: 0; /* Remove default button margins */
  }

  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
}

/* Add container styles */
:deep(.v-container) {
  max-width: none !important;
  width: 100% !important;
  padding: 16px !important;
}

:deep(.search-field) {
  .v-input__append-outer {
    margin-top: 0 !important;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .search-button {
    margin-left: 8px;
    margin-top: 8px;
  }
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
