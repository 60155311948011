



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  ref,
  onMounted,
  onUnmounted,
  reactive,
  nextTick
} from '@vue/composition-api';
import { Breakpoint } from 'vuetify/lib/services/breakpoint';
import { preset } from 'vuetify/lib/presets/default';
import { readFileSync } from 'fs';
import store from '../../../../store';
import { ActionTypes } from '../../../../store/modules/tools/actions';

export default defineComponent({
  name: 'SetupScopesWizard',
  props: {
    website: {
      required: true,
      type: String
    },
    scopes: {
      required: true,
      type: Array as () => string[]
    },
    loading: {
      required: true,
      type: Boolean
    },
    value: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const { emit } = context;
    const scopeRefs = ref();
    const customScopeRef = ref();
    const scope__containerRef = ref();

    const editingCustomScope = ref(false);
    const customScopeTitle = ref('');
    const customScopeDetails = ref('');
    const customScope = computed<string>({
      get: () => {
        const title = customScopeTitle.value || '';
        const details = customScopeDetails.value || '';
        return title + (title && details ? '.' : '') + details;
      },
      set: value => {
        const sentences = value?.split('.');
        customScopeTitle.value = sentences?.shift() || '';
        customScopeDetails.value = sentences?.join('.');
      }
    });

    const displayScopes = reactive(
      props.scopes.map(scope => {
        const scopeSentences = scope.split('.');
        return {
          base: scope,
          title: scopeSentences[0],
          details: scopeSentences.slice(1).join('.')
        };
      })
    );

    const createProgramDialog = ref(props.loading);
    /** --- BEGIN EVENT EMITTERS --- */
    function selectScope(content) {
      emit('input', content);
      const refs = [customScopeRef.value, ...scopeRefs.value];
      const targetRef = refs.find(vref => {
        return vref.innerText.split('\n').join('. ') === content;
      });
      targetRef?.focus();
    }
    /** --- BEGIN HELPERS --- */
    const loadingDataValue = ref({
      value: 0,
      message: 'Creating your program'
    });

    async function loadingData(duration = 30, parts = 6) {
      const messages = [
        'Creating your program',
        'Prepping your program',
        'Sending selected scope',
        'Generating program details',
        'Creating program',
        'Loading Data'
      ];
      const step = duration / parts;
      for (let n = 0, i = 0; n < duration; n += step, i++) {
        if (!props.loading) break;
        // console.log(n, i);
        // eslint-disable-next-line no-await-in-loop
        await new Promise<void>(_res => {
          setTimeout(() => {
            this.loadingDataValue.value = (n / 30) * 100;
            this.loadingDataValue.message = messages[i];
            _res();
          }, step * 1000);
        });
      }
      this.loadingDataValue.value = 95;
      this.loadingDataValue.message = 'Finishing Up..';
    }
    async function submitScope() {
      if (editingCustomScope.value) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Scope not saved! Save before continuing',
          isShowSnackbar: true
        });
        return;
      }
      emit('submitScope');
      await nextTick();
      if (!props.value) {
        return;
      }
      createProgramDialog.value = true;
      await loadingData();
    }
    function editScope(content) {
      this.customScope = content;
      this.selectScope(content);
      this.editingCustomScope = true;
      requestAnimationFrame(() => {
        this.scope__containerRef.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        });
      });
    }
    const breakpoint = ref(new Breakpoint(preset));
    const tileHeightRate = computed(() => {
      switch (breakpoint.value.name) {
        case 'xl':
          return 60 / 3;
        case 'lg':
          return 90 / 3;
        case 'md':
          return 120 / 3;
        default:
          return 150 / displayScopes.values.length;
      }
    });
    /** --- BEGIN LIFECYCLE HOOKS --- */
    onMounted(() => {
      window.addEventListener('resize', () => breakpoint.value.update());
      requestAnimationFrame(() => {
        scope__containerRef.value.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      });
      selectScope(customScope.value);
      // editingCustomScope.value = true;
    });
    onUnmounted(() => {
      window.removeEventListener('resize', () => breakpoint.value.update());
    });

    const dialogs = ref({
      strengths: false,
      passions: false,
      problems: false,
      jobs: false,
      careers: false,
      skills: false,
      tools: false,
      deliverables: false,
      goals: false,
      user: false,
      upload: false
    });

    const dialogInputs = ref({
      strengths: '',
      passions: '',
      problems: '',
      jobs: '',
      careers: '',
      skills: '',
      tools: '',
      deliverables: '',
      goals: '',
      user: '',
      upload: ''
    });

    const savedInputs = ref({
      strengths: localStorage.getItem('scope_strengths') || '',
      passions: localStorage.getItem('scope_passions') || '',
      problems: localStorage.getItem('scope_problems') || '',
      jobs: localStorage.getItem('scope_jobs') || '',
      careers: localStorage.getItem('scope_careers') || '',
      skills: localStorage.getItem('scope_skills') || '',
      tools: localStorage.getItem('scope_tools') || '',
      deliverables: localStorage.getItem('scope_deliverables') || '',
      goals: localStorage.getItem('scope_goals') || '',
      user: localStorage.getItem('scope_user') || '',
      upload: localStorage.getItem('scope_upload') || ''
    });

    const openDialog = type => {
      dialogs.value[type] = true;
      dialogInputs.value[type] = savedInputs.value[type];
    };

    const closeDialog = type => {
      dialogs.value[type] = false;
      dialogInputs.value[type] = ''; // Clear input
    };

    const saveDialog = type => {
      const input = dialogInputs.value[type];
      if (input.trim()) {
        // Save to localStorage
        localStorage.setItem(`scope_${type}`, input);
        savedInputs.value[type] = input;
      } else {
        // Clear from localStorage if empty
        localStorage.removeItem(`scope_${type}`);
        savedInputs.value[type] = '';
      }
      closeDialog(type);
    };

    const clearDialog = type => {
      localStorage.removeItem(`scope_${type}`);
      savedInputs.value[type] = '';
      dialogInputs.value[type] = '';
      dialogs.value[type] = false; // Close the dialog
    };

    const clearAllDialog = ref(false);

    const hasAnySavedInputs = computed(() => {
      return Object.values(savedInputs.value).some(value => value !== '');
    });

    const clearAll = () => {
      const types = [
        'strengths',
        'passions',
        'problems',
        'jobs',
        'careers',
        'skills',
        'tools',
        'deliverables',
        'goals',
        'user',
        'upload'
      ];

      types.forEach(type => {
        localStorage.removeItem(`scope_${type}`);
        savedInputs.value[type] = '';
        dialogInputs.value[type] = '';
      });

      clearAllDialog.value = false;
    };

    const generateAIPrompt = () => {
      const websiteUrl = props.website.startsWith('http')
        ? props.website
        : `https://${props.website}`;

      const template = `Create a clear, easy-to-read one sentence project scope relevant to the employer / organization that inspires immediate insight, ideas or solutions hosted by the relevant interests / real-world scenarios / use cases / objectives of this employer / organization (represented by their website URL below), and its related characteristics below that are of utmost relevance to today:

${
  props.website
    ? `FOR THIS EMPLOYER / ORGANIZATION (Represented by the following website URL and/or organization name. Include the organization name in the scope, but do not include the website URL):
${websiteUrl}`
    : ''
}

${
  customScope.value
    ? `UPGRADE THIS EXISTING SCOPE:
${customScope.value}`
    : ''
}

IN CONSIDERATION OF THESE SUGGESTED CHARACTERISTICS:

${
  savedInputs.value.careers
    ? `For Participants Exploring This Potential Pathway (But no need to mention the pathway unless relevant to the scope insight): ${savedInputs.value.careers}`
    : ''
}

${
  savedInputs.value.skills
    ? `That Ideally Utilizes These Skills (if it includes soft skills, instead of mentioning the soft skills directly, please write the scope to add methods to the scope for them to learn those soft skills instead of mentioning the soft skills name directly in the scope. If its a hard skill and its optional, then massage the word optional with other words that are synonmous): ${savedInputs.value.skills}`
    : ''
}

${
  savedInputs.value.tools
    ? `Utilizing Tools Including (If multiple tools are mentioned, only utilize the top most relevant tools that make most sense to the employer / organization's needs and the respective deliverables. Ideally, only one tool is selected to use in the scope if there are multiple tools listed, but if the deliverable and insight requests, an integration of tools is allowed. If the tool is broad, vague or general - then elaborate on any examples of that tool if helpful to the reader to get more specificity or options. If PilotCity, Pilot City or pilotcity is mentioned as a tool, please ignore as that is the platform we're creating these scopes on.): ${savedInputs.value.tools}`
    : ''
}

${
  savedInputs.value.user
    ? `That Serves These User / Customer (Feel free to mention the User / Customer if relevant to the scope insight): ${savedInputs.value.user}`
    : ''
}

${
  savedInputs.value.strengths
    ? `Consider What the Participant is Good At (Integrate transferrable items here into the DELIVERABLES, SKILLS and/or TOOL): ${savedInputs.value.strengths}`
    : ''
}

${
  savedInputs.value.passions
    ? `Consider What Participant Loves Doing (Integrate transferrable items here into the DELIVERABLES, SKILLS, PURPOSE / PROBLEM / WHY, and/or TOOL): ${savedInputs.value.passions}`
    : ''
}

${
  savedInputs.value.problems
    ? `Consider What The Participant Sees What The World Needs and Problems They'd Like to Solve (Integrate transferrable items here into the DELIVERABLES, PURPOSE / PROBLEM / WHY, and/or USER / CUSTOMER): ${savedInputs.value.problems}`
    : ''
}

${
  savedInputs.value.jobs
    ? `Consider What The Participant Would Like To Get Paid For (Integrate transferrable items here into the DELIVERABLES, SKILLS, PURPOSE / PROBLEM / WHY, TOOL, and/or USER / CUSTOMER): ${savedInputs.value.jobs}`
    : ''
}

${
  savedInputs.value.goals
    ? `For This Purpose / Problem / Why (Include the purpose / problem / why if relevant to the scope insight and be sure to adjust to make it directly relevant to the deliverable. This is probably the most important element to the scope as it provides hype, relevancy and context to create an ah-ha moment for the reader to understand WHY they're completing this project. Please make this key element a strong element to the scope. Include a very specific, yet feasible numeric key result, metric or set of numeric metrics that define exactly what the objective is - this will help the reader understand exactly what the outcome desired is. If the perspective of this purpose / problem / why is from a teacher perspective, translate it to a student reader perspective): ${savedInputs.value.goals}`
    : ''
}

${
  savedInputs.value.deliverables
    ? `Project Has This Required Deliverable (If the deliverable itself is general, then utilize the insight of what the organization might need / want / hire for that is most relevant to today's market and provide something specific about the deliverable to further paint a picture of what the participant could build for them. Include the deliverable as close to the beginning of the scope as possible. Include a very specific, yet feasible numeric key result, metric or set of numeric metrics that define exactly what the deliverable is and indicator of completion of the deliverable - this will help the reader understand exactly what to make and complete. If the perspective of this deliverable is from a teacher perspective, translate it to a student reader perspective. Creatively or directly factor into the deliverable the elements below of WHAT THE PARTICIPANT IS GOOD AT, LOVES DOING, SEES WHAT THE WORLD NEEDS, AND WHAT THE PARTICIPANT WOULD LIKE TO GET PAID FOR that will pique high motivation and excitement from the participant / reader): ${savedInputs.value.deliverables}`
    : ''
}

Be sure to find a use case, inspiration from a case study, or purpose specific to the employer to include an element of WHY the project scope option is of importance to the employer (paint a picture of an unique theoretical circumstance that the employer would undertake. Override any of the information above to paint this clear picture). REQUIRED (DO NOT FORGET TO INCLUDE THESE EVEN WITH THE FINAL POINT BELOW): Seamlessly include 1-3 short & sweet example deliverable solutions into the scope sentence that will open up the imagination of readers by providing them a clear picture of what to deliver & imaginative examples of what they can make themselves without influencing the direction of what they make. Do not include any quotations (") that enclose the scope. Write the scope so a middle schooler can understand.

Finally, review the final scope to assess its clarity for the reader & what is being asked of them, and emphasize what matters most to the participant to spark the greatest excitement, creativity and motivation while advancing novel innovation & niche markets for the employer`;

      // Remove empty lines and trim
      return template
        .split('\n')
        .filter(line => line.trim())
        .join('\n');
    };

    const copyAIPrompt = async () => {
      try {
        const prompt = generateAIPrompt();
        await navigator.clipboard.writeText(prompt);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'success',
          message: 'AI Prompt copied to clipboard!',
          isShowSnackbar: true
        });
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to copy prompt to clipboard',
          isShowSnackbar: true
        });
      }
    };

    const trimCustomScope = () => {
      if (customScope.value) {
        customScope.value = customScope.value.trim();
      }
    };

    const uploadedFile = ref(null);
    const uploading = ref(false);

    const handleFileUpload = async file => {
      if (!file) return;
      uploadedFile.value = file;
    };

    const handleSaveUpload = async () => {
      if (!uploadedFile.value) return;

      try {
        uploading.value = true;

        // Store the file name
        savedInputs.value.upload = uploadedFile.value.name;
        localStorage.setItem('scope_upload', uploadedFile.value.name);

        // Here you would typically handle the actual file upload
        // For example:
        // const formData = new FormData();
        // formData.append('file', uploadedFile.value);
        // await axios.post('/api/upload', formData);

        closeDialog('upload');
        uploadedFile.value = null;

        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'success',
          message: 'File uploaded successfully!',
          isShowSnackbar: true
        });
      } catch (error) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to upload file',
          isShowSnackbar: true
        });
      } finally {
        uploading.value = false;
      }
    };

    const copyAndOpenChatGPT = async () => {
      try {
        const prompt = generateAIPrompt();
        await navigator.clipboard.writeText(prompt);
        window.open('https://chat.openai.com', '_blank');

        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'success',
          message: 'Prompt copied! Opening ChatGPT...',
          isShowSnackbar: true
        });
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to copy prompt',
          isShowSnackbar: true
        });
      }
    };

    const finishEditing = () => {
      selectScope(customScope.value);
      editingCustomScope.value = false;
    };

    const quickFinish = async () => {
      finishEditing();
      await submitScope();
    };

    return {
      createProgramDialog,
      editingCustomScope,
      breakpoint,
      displayScopes,
      tileHeightRate,
      customScope,
      customScopeTitle,
      customScopeDetails,
      submitScope,
      selectScope,
      loadingDataValue,
      loadingData,
      editScope,
      scopeRefs,
      customScopeRef,
      scope__containerRef,
      dialogs,
      dialogInputs,
      openDialog,
      closeDialog,
      saveDialog,
      savedInputs,
      clearDialog,
      clearAllDialog,
      clearAll,
      hasAnySavedInputs,
      copyAIPrompt,
      trimCustomScope,
      uploadedFile,
      uploading,
      handleFileUpload,
      handleSaveUpload,
      copyAndOpenChatGPT,
      finishEditing,
      quickFinish
    };
  }
});
